import classNames from "classnames";
import React, { useState } from "react";
import video from "../../assets/images/vid.gif";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  // const openModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(true);
  // };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );


  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              Your own travel friend {" "}
              <span className="text-color-primary">Travel Guru</span>
            </h1>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Unlock the hidden gems of your travel destination with our app, your ultimate guide to a memorable and hassle-free journey.
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <a href="https://play.google.com/store/apps/details?id=com.travelguru.app&pcampaignid=web_share">
                    <Image
                      className="has-shadow"
                      src={require("./../../assets/images/google-play-badge.png")}
                      alt="Hero"
                      width={300}
                      height={300}
                    />
                  </a>
                  <a href="https://apps.apple.com/in/app/travel-guru-app/id6463500236">
                    <Image
                      className="has-shadow"
                      src={require("./../../assets/images/apple.png")}
                      alt="Hero"
                      width={260}
                      height={260}
                    />
                  </a>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            {/* <a
              data-video={video}
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require("./../../assets/images/video-placeholder.jpg")}
                alt="Hero"
                width={896}
                height={504}
              />
            </a> */}
            {/* <video id="video-player" controls autoPlay>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video> */}
            <Image src={video} alt="sowebmail introduction" />
          </div>
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video={video}
            videoTag="iframe"
          />
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
