import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Do more with AI",
    paragraph:
      "We are a cutting-edge platform that leverages the power of artificial intelligence to revolutionize the way you plan and organize your trips. With our advanced AI features, we make travel planning hassle-free, efficient, and enjoyable",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Experience the location based nearby things
                </div>
                <h3 className="mt-0 mb-12">Travel Guru</h3>
                <p className="m-0">
                  Introducing the ultimate travel companion: the Location-Based Travel App! Your go-to tool for seamless and worry-free travel, this app is designed to make your journeys safer, more enjoyable, and effortlessly organized. Whether you're a seasoned globetrotter or embarking on your first adventure, this app will be your trusted guide every step of the way.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/travelguru/first.png")}
                  alt="Features split 01"
                  style={{ width: 400, height: "auto" }}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  In any emergency you can contact your embassy
                </div>
                <h3 className="mt-0 mb-12">Find your embassy</h3>
                <p className="m-0">
                  With just a few taps on your device, you can access a comprehensive database of embassy details from around the world. We provide up-to-date contact information, including phone numbers, email addresses, and physical addresses, for the embassies of various countries. This vital resource is available even when your internet connection is limited, ensuring that you're never left stranded in a moment of crisis.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/travelguru/embassy.png")}
                  alt="Features split 02"
                  style={{ width: 400, height: "auto" }}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Find new places always
                </div>
                <h3 className="mt-0 mb-12">AI iternary builder</h3>
                <p className="m-0">
                  Introducing our revolutionary travel app that takes the stress out of planning your trips. Say goodbye to hours spent researching destinations and creating itineraries - our app does all the hard work for you. With our advanced algorithm, we can generate customized itineraries based on the number of days you have available and the places you want to visit.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/ai_iternary_page.png")}
                  alt="Features split 03"
                  style={{ width: 400, height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
