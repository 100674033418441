import classNames from "classnames";
import React from "react";
import AboutIMG from '../assets/images/about.jpg';
import { SectionProps } from "../utils/SectionProps";
const propTypes = {
    ...SectionProps.types,
};

const defaultProps = {
    ...SectionProps.defaults,
};

const About = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {
    // const [videoModalActive, setVideomodalactive] = useState(false);

    // const openModal = (e) => {
    //     e.preventDefault();
    //     setVideomodalactive(true);
    // };

    // const closeModal = (e) => {
    //     e.preventDefault();
    //     setVideomodalactive(false);
    // };

    const outerClasses = classNames(
        "hero section",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
    );

    const innerClasses = classNames(
        "hero-inner section-inner",
        topDivider && "has-top-divider",
        bottomDivider && "has-bottom-divider"
    );

    return (
        <section {...props} className={outerClasses}>
            <div className="container-sm">
                <div className={innerClasses}>
                    <img src={AboutIMG} alt="travelguru about us" />
                    <h4>About us</h4>
                    <p></p>
                    <p style={{ fontSize: 16 }}>
                        We are a passionate team of five AI enthusiasts and tech lovers. With a shared vision for harnessing the power of artificial intelligence, we are dedicated to developing innovative solutions that enhance the way people interact with technology.
                        <br />
                        Our team is driven by a deep curiosity and enthusiasm for AI, constantly exploring new possibilities and pushing the boundaries of what is possible. We believe that AI has the potential to revolutionize the way we work, communicate, and live our lives.
                        <br />
                        With our expertise in AI technologies, we strive to create user-friendly and intelligent products that make a positive impact on people's daily lives. Our goal is to empower individuals and businesses by providing them with cutting-edge tools that simplify complex tasks and improve productivity.
                        <br />
                        Join us on this exciting journey as we continue to innovate and shape the future of AI. Together, we can unlock the full potential of technology and create a world where AI powers a better and more efficient tomorrow.

                    </p>
                    <br /><br />
                    <h4>Vision</h4>
                    <p></p>
                    <p style={{ fontSize: 16 }}>
                        We envision a future where AI-powered travel tools make journey more efficient, accurate, and personalized. Our goal is to make travel effortless and safe so that individuals..
                        <br />
                        Through innovative AI technologies and forward-thinking solutions, we aim to make intelligent travel solution accessible to everyone. We strive to empower everyone to be travel more with safety, security and hassel free.
                    </p>
                </div>
            </div>
        </section>
    );
};

About.propTypes = propTypes;
About.defaultProps = defaultProps;

export default About;
