import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import Rubust from "./../../assets/images/feature-tile-icon-01.svg";
import EasyMailBox from "./../../assets/images/feature-tile-icon-02.svg";
import Multilingual from "./../../assets/images/feature-tile-icon-03.svg";
import IUI from "./../../assets/images/feature-tile-icon-04.svg";
import SEARCH from "./../../assets/images/feature-tile-icon-05.svg";
import SYNC from "./../../assets/images/feature-tile-icon-06.svg";
import HOTEL from "./../../assets/images/hotel.svg";
import REST from "./../../assets/images/rest.svg";
import VISA from "./../../assets/images/visa.svg";
import TAXI from "./../../assets/images/taxi.svg";
import HOSPITALS from "./../../assets/images/hospital.svg";
import POLICE from "./../../assets/images/police.svg";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "What is Travel Guru",
    paragraph:
      "Discover Your Travel Essentials with Ease - Your One-Stop Solution for All Your Travel Needs.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={HOTEL}
                      alt="Features tile icon 01"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Find Nearest Hotels</h4>
                  <p className="m-0 text-sm">
                    locate the closest hotels based on your current location or desired area.
                    Whether you're looking for luxury accommodations or budget-friendly options,
                    we've got you covered.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <img
                      src={REST}
                      alt="Features tile icon 02"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Nearby Restaurants</h4>
                  <p className="m-0 text-sm">
                    Discover an array of dining options near you.
                    From local cuisine to international flavors, you can easily find place to satisfy your food cravings
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={VISA}
                      alt="Features tile icon 03"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Visa Services</h4>
                  <p className="m-0 text-sm">
                    Our app provides information and guidance on visa procedures,
                    ensuring you have a smooth and hassle-free travel authorization process
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={TAXI}
                      alt="Features tile icon 04"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Taxi Services</h4>
                  <p className="m-0 text-sm">
                    Getting around in an unfamiliar city can be daunting. Our app connects you with reliable taxi services,
                    allowing you to conveniently book transportation with just a few taps.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={HOSPITALS}
                      alt="Features tile icon 05"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Locate Hospitals</h4>
                  <p className="m-0 text-sm">
                    Your health and well-being are paramount. In case of emergencies or medical needs,
                    our app helps you find the nearest hospitals or medical facilities,
                    providing peace of mind during your travels.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={POLICE}
                      alt="Features tile icon 06"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Emergency Contacts</h4>
                  <p className="m-0 text-sm">
                    We understand that unexpected situations may arise.
                    Our app provides quick access to contact information for local police stations,
                    ensuring you have the necessary support and assistance when needed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
