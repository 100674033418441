import classNames from "classnames";
import React, { useState } from "react";
import { SectionProps } from "../utils/SectionProps";
const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Privacy = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <h4>Privacy policy</h4>
          <p></p>
          <p style={{ fontSize: 16 }}>
            Webmail Privacy Policy WebMail is an email client for Android and
            Ios that uses the user’s email provider to send and retrieve
            messages. The app requests the following privacy-relevant Android
            permissions:
            <ul>
              <li>Read/modify your Contacts</li>
              <li>
                The names and email addresses in the user’s address book
                (contacts) are used to provide suggestions and autocomplete
                recipients when composing a message.
              </li>
              <li>Read from/write to Storage (Photos/Media/Files)</li>
              <li>
                The storage area is used to save attachments or export app
                settings. It can also be used to import app settings or select
                files to attach to a message the user is composing.
              </li>
            </ul>
            For more information on the permissions WebMail requires see:
            <br />
            In order to perform various tasks and provide a cohesive email
            client, WebMail uses various permissions. See our privacy policy for
            information on how we handle personal information obtained via these
            permissions.
            <br />
            <br />
            <h5>Network Communication</h5>
            <u>Full Internet Access</u>
            <br />
            In order to connect to your email server, WebMail needs the ability
            to create and manage network connections. We do this with careful
            consideration for both battery life and security - only opening
            connections as needed.
            <h5>Storage</h5>
            <u>External Storage Modification</u>
            <br />
            Attachments and settings can be loaded and saved onto external
            storage. In order to read and write these files we need access to do
            so.
            <h5>Extra/Custom</h5>
            <u>Read Contacts</u>
            <br />
            In order to mark email that comes from your contacts and use your
            contacts to provide email address autocompletion, we need access to
            your contacts
            <br />
            <br />
            <u>Write Contacts</u>
            <br />
            In order to note that a contact has been emailed we need
            write-access.
            <br />
            <br />
            <u>Control Vibration</u>
            <br />
            One of the settings of WebMail is to vibrate on receiving an email.
            In order to do this we need control of vibration.
            <br />
            <br />
            <u>Prevent Device from Sleeping</u>
            <br />
            When we are in the middle of downloading email we want to be able to
            finish sending / receiving that request.
            <br />
            <br />
            <u>Automatically Start on Boot</u>
            <br />
            In order to pick up your email when the device is started we need to
            be able start on boot.
            <br />
            <br />
            <u>View Network State</u>
            <br />
            In order to determine whether we should try and connect we need to
            be able to know the network state.
            <br />
            <br />
            <u>Read Sync Settings</u>
            <br />
            In order to fetch contacts properly we need this permission
            Sensitive user information is only used to perform the basic
            functionality of the app, sending and receiving email. WebMail does
            not automatically collect and send data to the developers of the app
            or any third party. Sensitive information is also not included in
            application logs unless this is enabled by the user.
            <h4>Collecting and Using Your Personal Data</h4>
            <h5>Google API services</h5>
            <p></p>
            <p>Webmail app use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" style={{ color: '#4187ff' }} rel="noreferrer">Google API Services User Data Policy</a>, including the Limited Use requirements. </p>
            <h5>AI services (Only if AI assist feature enabled by user)</h5>
            <p></p>
            <p>Webmail AI Assist can help you to compose, send and reply emails. Your data is kept locally on your device and never used to train the system. By enabling this feature, you agree with <a href="https://sowebmail.com/privacy" target="_blank" style={{ color: '#4187ff' }} rel="noreferrer"> Webmail Privacy Policy</a> and <a href="https://privacy.microsoft.com/en-us/privacystatement" target="_blank" style={{ color: '#4187ff' }} rel="noreferrer">Microsoft Privacy Statement.</a></p>

            <h5>Personal Data</h5>
            <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>

            <ul>
              <li>
                <p>Email address</p>
              </li>
              <li>
                <p>Usage Data</p>
              </li>
            </ul>
            <h5>Usage Data</h5>
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
            <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
            <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
            <h5>Information Collected while Using the Application</h5>
            <p>While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:</p>
            <ul>
              <li>Information from your Device's phone book (contacts list)</li>
            </ul>
            <p>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.</p>
            <p>You can enable or disable access to this information at any time, through Your Device settings.</p>

            <u>Information Collection and Use</u>
            <br />
            For a better experience, while using our Service, I may require you
            to provide us with certain personally identifiable information,
            including but not limited to x,x. The information that I request
            will be retained on your device and is not collected by me in any
            way. The app does use third party services that may collect
            information used to identify you. Link to privacy policy of third
            party service providers used by the app
            <ul>
              <li>Google Play Services</li>

              <li>AdMob</li>
              <li>Firebase Analytics</li>
            </ul>
            <h5>Log Data</h5>I want to inform you that whenever you use my
            Service, in case of an error in the app I collect data and
            information (through third party products) on your phone called Log
            Data. This Log Data may include information such as your device
            Internet Protocol (“IP”) address, device name, operating system
            version, the configuration of the app when utilizing my Service, the
            time and date of your use of the Service, and other statistics.
            <h5> Cookies </h5>
            Cookies are files with a small amount of data that are commonly used
            as anonymous unique identifiers. These are sent to your browser from
            the websites that you visit and are stored on your device's internal
            memory. This Service does not use these “cookies” explicitly.
            However, the app may use third party code and libraries that use
            “cookies” to collect information and improve their services. You
            have the option to either accept or refuse these cookies and know
            when a cookie is being sent to your device. If you choose to refuse
            our cookies, you may not be able to use some portions of this
            Service.
            <h5> Service Providers </h5>I may employ third-party companies and
            individuals due to the following reasons:
            <ul>
              <li>To facilitate our Service;</li>

              <li>To provide the Service on our behalf;</li>

              <li> To perform Service-related services; or </li>
              <li> To assist us in analyzing how our Service is used.</li>
            </ul>
            I want to inform users of this Service that these third parties have
            access to your Personal Information. The reason is to perform the
            tasks assigned to them on our behalf. However, they are obligated
            not to disclose or use the information for any other purpose.
            <h5>Security </h5>I value your trust in providing us your Personal
            Information, thus we are striving to use commercially acceptable
            means of protecting it. But remember that no method of transmission
            over the internet, or method of electronic storage is 100% secure
            and reliable, and I cannot guarantee its absolute security.
            <h5>Links to Other Sites</h5>
            This Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by me. Therefore, I strongly advise
            you to review the Privacy Policy of these websites. I have no
            control over and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
            <h5>Children’s Privacy</h5>
            These Services do not address anyone under the age of 13. I do not
            knowingly collect personally identifiable information from children
            under 13. In the case I discover that a child under 13 has provided
            me with personal information, I immediately delete this from our
            servers. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact me
            so that I will be able to take necessary actions.
            <h5> Changes to This Privacy Policy </h5>I may update our Privacy
            Policy from time to time. Thus, you are advised to review this page
            periodically for any changes. I will notify you of any changes by
            posting the new Privacy Policy on this page. These changes are
            effective immediately after they are posted on this page.
            <h5>Contact Us </h5>
            If you have any questions or suggestions about my Privacy Policy, do
            not hesitate to contact me.
          </p>
        </div>
      </div>
    </section>
  );
};

Privacy.propTypes = propTypes;
Privacy.defaultProps = defaultProps;

export default Privacy;
